import { Button, Caption1, makeStyles, tokens } from '@fluentui/react-components'
import type { ReactElement } from 'react'
import { t } from '../../util/intl/t'
import { LATEST_VERSION, useNotifyVersion } from './help'

const useStyles = makeStyles({
  banner: {
    borderRadius: '24px',
    width: 'auto',
    position: 'fixed',
    backgroundColor: tokens.colorBrandForeground1,
    padding: `${tokens.spacingVerticalXXS} ${tokens.spacingVerticalS}`,
    left: '50%',
    transform: 'translate(-50%, 0)',
    display: 'flex',
    justifyContent: 'space-between',
    gap: tokens.spacingHorizontalXS,
    boxShadow: tokens.shadow16,
    alignItems: 'center',
    color: 'white',
    height: '28px',
  },
  message: {
    display: 'flex',
    alignItems: 'baseline',
  },
  action: {
    display: 'flex',
    gap: tokens.spacingHorizontalXS,
  },
  button: {
    width: 'auto',
    fontSize: '12px',
    color: 'white',
    minWidth: '36px',
    height: '22px',
  },
})

export function AppNotifyVersion(): ReactElement | null {
  const s = useStyles()

  const { updateVersion, version } = useNotifyVersion()

  if (version.seen === 'true')
    return null

  const onClose = () => {
    updateVersion({ ...version, seen: 'true' })
  }

  return (
    <div className={s.banner}>
      <Caption1 className={s.message}>
        {`${t('app.notify.new-version')} ${LATEST_VERSION}`}
      </Caption1>
      <div className={s.action}>
        <Button
          as="a"
          size="small"
          appearance="primary"
          className={s.button}
          href="https://docs.aisekisan.com"
          target="_blank"
          onClick={onClose}
        >
          {t('app.notify.learn-more')}
        </Button>
        <Button
          appearance="subtle"
          size="small"
          className={s.button}
          onClick={onClose}
        >
          {t('app.notify.hide')}
        </Button>
      </div>
    </div>
  )
}
